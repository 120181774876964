<template>
    <div class="home mgl">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/usermanagement/authenticateduserlist')" class="el-icon-close"></i>
      </div>
      <h4>基本信息</h4>
      <div>用户账号：{{form.user.id}}</div>
      <div>用户编号：{{form.user.uid}}</div>
      <div>昵称：{{form.user.nickname}}</div>
      <h4>认证信息</h4>
      <div>认证编号：{{form.id}}</div>
      <div>认证状态：{{form.status == 1?'启用':''}}{{form.status == 2?'禁用':''}}{{form.status == 3?'失效':''}}</div>
      
      <div class="pt20"></div>
        <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item :required="true" label="认证类型">
            <el-select v-model="form.category" placeholder="类型">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            </el-form-item><br>
            <el-form-item :required="true" label="有效时间">
                <el-date-picker
                    v-model="timevalue"
                    type="datetimerange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
      <div class="pt20"></div>
            <el-form-item style="width:100%;">
            <el-button v-if="!look" type="primary" @click="onSubmit()" style="margin-left: 45%;" >提交</el-button>
            </el-form-item>
      </el-form> 
      <div class="pt20"></div>

        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat2'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
        user:{
          keyid:'',
          id:'',
          nickname:'',
        }
      },
      look:false,
      options:[],   
      timevalue:[],
        loading: false,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
      onSubmit(){
          if(this.timevalue){
            this.form.sdate = formatDate(this.timevalue[0]);
            this.form.edate = formatDate(this.timevalue[1]);
          }
        let _this = this;
        axios.post(config.authentication,this.form)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/usermanagement/authenticateduserlist');
            }else if(response.data.code == 422){
                _this.$message.error('请填写必填项');
            }else{
                _this.$message.error(response.data.message);
            }
                
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      goNav(url){
        this.$router.push(url)
      },
    ininoptions(){
        let _this = this;
        axios.get(config.selectauthentication,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        axios.get(config.authenticationInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.timevalue.push(new Date(_this.form.sdate))
                _this.timevalue.push(new Date(_this.form.edate))
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
      this.ininoptions();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
.mgl{
  text-align: left;
}
.el-icon-close:before{
  font-size: 24px;
}

div{
    vertical-align: middle;
    font-size: 16px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
</style>